const particleConfig = {
  fullScreen: { enable: false },
  background: {
    color: {
        value: "#f4eee8",
    },
    
},
fpsLimit: 120,
interactivity: {
    events: {
        onClick: {
            enable: true,
            mode: "push",
        },
        onHover: {
            enable: true,
            mode: "repulse",
        },
        resize: true,
    },
    modes: {
        push: {
            quantity: 4,
        },
        repulse: {
            distance: 200,
            duration: 0.4,
        },
    },
},
particles: {
    color: {
        value: "#055052",
    },
    links: {
        color: "#055052",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
    },
    move: {
        direction: "none",
        enable: true,
        outModes: {
            default: "bounce",
        },
        random: false,
        speed: 6,
        straight: false,
    },
    number: {
        density: {
            enable: true,
            area: 800,
        },
        value: 80,
    },
    opacity: {
        value: 0.5,
    },
    shape: {
        type: "circle",
    },
    size: {
        value: { min: 1, max: 5 },
    },
},
detectRetina: true,
}

export default particleConfig;