import React from 'react';
import ReactDOM from 'react-dom';
import Projects from './components/projects/Projects';
import Icons from './components/home/Icons';


function Project() {
  return (
    <>
    <Projects />
    <Icons />
    </>
  )
}

export default Project;
