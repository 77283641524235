import React from 'react';
import ParticlesBackground from '../config/particlesBackground';

function Intro() {
  return (
    <main className='mainIntro'>
      <ParticlesBackground/>
        <div className="intro">
          <div className="container left">
            <div className="col-sm-12 col-md-12 col-lg-12">
              {/* <h3 className="intro-greeting">Hi there</h3> */}
              <h3 className="intro-name">Hey! I'm Andrew</h3>
              {/* <h3 className="intro-name">I'm Andrew</h3> */}
              <h3 className="intro-text type">Welcome to my site.</h3>

            </div>
            {/* <div className="col-sm-11 col-md-12 col-lg-6">
              <div className="right">
              <img className="intro-image" src="../../../images/Pngtree_adobespark.png" />
              </div>
            </div> */}
          </div>

        </div>
        <a href="#profile"><div className="scrolldown">
          <span></span>
          <span></span>
          <span></span>
        </div></a>
    </main>
  )
}

export default Intro;