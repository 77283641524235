import particlesConfig from "./particles-config";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const ParticlesBackground = () => {
    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (<div>
        <Particles
        id="tsparticles"
            init={particlesInit}
            options={particlesConfig}
        />
        </div>
    );
};

export default ParticlesBackground;