import React from 'react';
import ContactForm from "../contact/ContactClone.jsx";

function Contact() {
  return (
    <main id="contact">
      <div>
            <ContactForm />
      </div>
    </main>
  )
}

export default Contact;
