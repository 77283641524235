import React from 'react';
import ReactDOM from 'react-dom';
import ProfileHome from './components/profilehome/ProfileHome';
import Icons from './components/home/Icons';


function Profile() {
  return (
    <>
    <ProfileHome />
    <Icons />
    </>
  )
}

export default Profile;
